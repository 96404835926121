import React from 'react';
import {isMobileOnly} from 'react-device-detect';
import {Link, NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import HeaderMobile from "./HeaderMobile";
import './Header.css';

const Header = ({animation}) => {
    const {t} = useTranslation();

    return (
        <React.Fragment>
            {isMobileOnly ?
                <HeaderMobile/>
                :
                <header className={`page-header fade ${animation ? 'in' : ''}`}>
                    <nav className='nav'>
                        <Link to={'/'} className='nav-link'>{t("Main")}</Link>
                        <NavLink to={'/teams'} className='nav-link' activeClassName="active">{t("Teams")}</NavLink>
                        <NavLink to={'/events'} className='nav-link' activeClassName="active">{t("Events")}</NavLink>
                        <NavLink to={'/statistics'} className='nav-link' activeClassName="active">{t("Statistics")}</NavLink>
                        <NavLink to={'/info'} className='nav-link' activeClassName="active">{t("Info")}</NavLink>
                    </nav>
                </header>
            }
        </React.Fragment>
    )
}

export default Header;