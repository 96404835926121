import React from 'react';
import './Info.css';

import PageWrapper from "../components/PageWrapper";
import {useTranslation} from "react-i18next";

const Info = () => {
    const {t} = useTranslation();

    return (
        <PageWrapper>
            <h2 className='page-title'>
                {t("Information about the tournament")}
            </h2>
            <p className='page-subtitle'>
                Eternity League
            </p>
            <div className='info'>
                <p>
                    {t('CS-GO')}
                </p>
                <p>
                    {t('Will be broadcast on the twitch platform channels')}:<br/>
                    <a href="https://www.twitch.tv/eternity_league1">https://www.twitch.tv/eternity_league1</a><br/>
                    <a href="https://www.twitch.tv/eternity_league2">https://www.twitch.tv/eternity_league2</a>
                </p>
                <p>{t('League format')}:</p>
                <p>{t('Group stage')}:{t(' 30 teams each play one time, 29 BO3 matches are to be played by each of the league participants.')}</p>
                <p>{t('According to the results of the group stage, 8 teams with the highest number of points advance to the playoffs, 9-24 places await the next season, the 6 worst leave the league.')}</p>
                <p>{t('Play-off')}:{t(' 8 best teams of the season play double elimination, bo3.')}</p>
                <p>{t('Prize fund distribution:')}</p>
                <p>
                    {t('1st place - $7500')}<br/>
                    {t('2nd place - $4000')}<br/>
                    {t('3rd place - $2500')}<br/>
                    {t('4th place - $1000')}<br/>
                </p>
            </div>
        </PageWrapper>
    )
}

export default Info;