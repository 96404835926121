import React, {useEffect, useState} from 'react';
import {isMobileOnly} from 'react-device-detect';
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Footer from "../components/Footer";
import './Main.css';
import HeaderMobile from "../components/HeaderMobile";

const Main = () => {
    const {t} = useTranslation();
    const [animateNav, setAnimateNav] = useState(false);
    const [animateBody, setAnimateBody] = useState(false);
    const [animateFooter, setAnimateFooter] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setAnimateNav(true)
            setTimeout(() => {
                setAnimateBody(true)
                setTimeout(() => {
                    setAnimateFooter(true)
                }, 500)
            }, 500)
        }, 500)
    }, [])

    return (
        <React.Fragment>
            {isMobileOnly &&
                <HeaderMobile/>
            }
            <main className='hero'>
                {!isMobileOnly &&
                    <nav className={`hero-nav fade ${animateNav ? 'in' : ''}`}>
                        <Link to={'/teams/'} className='nav-link'>{t("Teams")}</Link>
                        <Link to={'/events/'} className='nav-link'>{t("Events")}</Link>
                        <div className='nav-link-logo'/>
                        <Link to={'/statistics/'} className='nav-link'>{t("Statistics")}</Link>
                        <Link to={'/info/'} className='nav-link'>{t("Info")}</Link>
                    </nav>
                }
                <section className={`hero-content fade ${animateBody ? 'in' : ''}`}>
                    {isMobileOnly &&
                        <div className='nav-link-logo mobile'/>
                    }
                    <p>Esports tournament</p>
                    <h1>Eternity League</h1>
                    <Link to={'/statistics'} className='hero-link'>{t("Statistics")}</Link>
                </section>
            </main>
            <Footer animation={animateFooter}/>
        </React.Fragment>
    )
}

export default Main;